<template>
  <header
    class="fixed inset-x-0 z-[9999] hidden w-full pt-4 lg:block transition-all duration-150"
    :class="navClasses"
  >
    <div
      class="container grid justify-between grid-cols-2 gap-4 transition-all"
      :class="[
        (state.scrollPosition >= 80 && !state.isProductPage) ||
        state.isStorePage
          ? 'h-[88px]'
          : 'h-24 is-not-scroll',
      ]"
    >
      <nav class="flex items-center space-s-7">
        <!-- Models -->
        <LazyFlyoutMenu v-if="products.length > 0" is-product>
          <a
            class="cursor-pointer nav-link"
            :class="[
              state.isProductPage ? 'nav-product text-secondary-dark' : '',
              state.scrollPosition >= 80 || state.isStorePage
                ? 'text-black hover:text-black'
                : 'text-white hover:text-white',
              newTextClass,
            ]"
            v-text="$t('common.products')"
          />
        </LazyFlyoutMenu>

        <!-- News -->
        <nuxt-link
          v-if="currentSite && currentSite.news"
          :to="$i18nPath(store.pageLinks['news'])"
          class="nav-link"
          :class="[state.isProductPage ? 'nav-product' : '', newTextClass]"
          :prefetch="false"
        >
          {{ $t("common.news") }}
        </nuxt-link>
        <nuxt-link
          v-if="store.enableShop"
          :to="$i18nPath('/search')"
          class="nav-link"
          :class="[state.isProductPage ? 'nav-product' : '', newTextClass]"
          :prefetch="false"
        >
          {{ $t("common.shop_online") }}
        </nuxt-link>
      </nav>

      <nuxt-link
        :to="$i18nPath('/')"
        class="absolute transition-all transform -translate-s-1/2 start-1/2"
        :class="[
          (state.scrollPosition >= 80 && !state.isProductPage) ||
          state.isStorePage
            ? 'top-6'
            : 'top-[17px] lg:top-10',
        ]"
      >
        <Logo
          :color="
            state.scrollPosition >= 80 ||
            state.isStorePage ||
            state.isProductPage
              ? '#F54308'
              : 'white'
          "
          class="transition-all"
          :class="
            (state.scrollPosition >= 80 || state.isStorePage) &&
            !state.isProductPage
              ? 'h-10'
              : 'h-11'
          "
        />
      </nuxt-link>

      <nav class="flex items-center justify-end ltr:space-s-7 my-[22px]">
        <!-- Stores -->
        <nuxt-link
          :to="$i18nPath(store.pageLinks['stores'])"
          class="nav-link"
          :class="[state.isProductPage ? 'nav-product' : '', newTextClass]"
          :prefetch="false"
        >
          {{ $t("common.stores") }}
        </nuxt-link>

        <!-- Contact us -->
        <nuxt-link
          :to="$i18nPath(store.pageLinks['contact'])"
          class="nav-link rtl:mr-6"
          :class="[state.isProductPage ? 'nav-product' : '', newTextClass]"
        >
          {{ $t("common.contact") }}
        </nuxt-link>

        <LazyLocaleSelector
          :name="state.isProductPage ? 'headerproduct' : 'header'"
          class="px-4"
          :color="color"
          :class="store.enableShop ? 'border-r' : 'border-none'"
        />
        <!-- User selector -->
        <LazyUserDropdown v-if="store.enableShop" :color="color" />
      </nav>
    </div>
  </header>
</template>
<script setup>
import { useMainStore } from "@/store/index";

const nuxtApp = useNuxtApp();
const store = useMainStore();
const route = useRoute();
const router = useRouter();

const $i18nPath = nuxtApp.$i18nPath;
const color = ref("white");
const state = reactive({
  isStorePage: false,
  isProductPage: false,
  scrollPosition: 0,
});

state.isStorePage = !!(route.name == "country-stores");

const navClasses = computed(() => {
  const classes = [];
  /* } else if (removeLastDash($i18nPath($route.path)) === removeLastDash($i18nPath('/'))) {
    classes.push('bg-opacity-20')
  } */
  if (route.path.includes("/products/")) {
    classes.push("md:static md:mb-[-110px] bg-opacity-0 ");
  } else if (state.scrollPosition >= 80 && !state.isProductPage) {
    classes.push(
      "bg-opacity-60 !pt-0 shadow bg-[#232323] backdrop-filter backdrop-blur "
    );
  }
  if (state.isStorePage) {
    classes.push("bg-white !pt-0 shadow");
  }
  return classes;
});

const newTextClass = computed(() => {
  if (store.locale === "el" || store.locale === "ar") {
    return "xl:text-[20px] text-[16px]";
  } else {
    return "xl:text-[28px] text-[24px] ";
  }
});
const products = computed(() => {
  return store.products;
});

const categories = store.categories;
const currentSite = store.currentSite;
const currentSiteSettings = store.currentSiteSettings;

// watch(
//   router.currentRoute,
//   (newValue, oldValue) => {
//     if (newValue.name == "country-products-slug") {
//       state.isProductPage = true;
//     } else {
//       state.isProductPage = false;
//     }
// const pageName = nuxtApp.$getKeyByValue(
//   store.pageLinks,
//   router.currentRoute.value.params.page
// );
// if (pageName == "stores") {
//       state.isStorePage = true;
//     } else {
//       state.isStorePage = false;
//     }
//   },
//   { deep: true }
// );

onBeforeMount(() => {
  if (!process.browser) {
    return;
  }
  state.isProductPage = window.location.href.includes("/products/");
});

onMounted(() => {
  if (!process.browser) {
    return;
  }
  if (import.meta.client) {
    nuxtApp.hook("page:loading:end", () => {
      if (router.currentRoute.value.name == "country-products-slug") {
        state.isProductPage = true;
      } else {
        state.isProductPage = false;
      }

      const pageName = nuxtApp.$getKeyByValue(
        store.pageLinks,
        router.currentRoute.value.params.page
      );

      if (pageName == "stores") {
        state.isStorePage = true;
      } else {
        state.isStorePage = false;
      }
    });
  }
  window.addEventListener("scroll", updateScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", updateScroll);
});

function updateScroll() {
  state.scrollPosition = window.scrollY;
}

function changeColor(color) {
  color.value = color;
}
</script>

<style lang="postcss">
.directionltr {
  direction: ltr;
}
.directionrtl {
  direction: rtl;
}
.nav-link {
  @apply z-10 text-base text-gray-300  hover:no-underline focus:no-underline hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-primary-500 hover:to-green-200 focus:outline-none font-semibold tracking-tighter;
  /* &.hover-effect::before {
    transform: scaleX(1);
  } */
  &.nav-product {
    @apply text-[#5a5a5a];
    /* &.hover-effect::before {
      @apply bg-[#5A5A5A] !important;
    } */
  }
  &.nuxt-link-exact-active {
    @apply text-primary;
  }
  &.router-link-exact-active {
    @apply text-primary;
  }
}
</style>
