export default defineNuxtPlugin((nuxtApp) => {
  const route = useRoute();
  const $config = nuxtApp.$config.public;
  const seoModuleFunc = async (props) => {
    props = {
      page_title: $config.APP_NAME,
      url: $config.APP_URL + route.fullPath,
      meta_description: null,
      og_image: null,
      meta_name: props.meta_name + " | " + $config.APP_NAME,
      og_title: props.og_name,
      ...props,
    };

    if (props.model_type != "Product" && props.model_type != "Post") {
      try {
        const { data } = await nuxtApp.$api(
          `/api/front/seo/${props.model_id}/${props.model_type}`
        );

        props = {
          ...props,
          ...data,
        };
      } catch (e) {
        console.log(e);
      }
    }

    const attributes = {};

    if (props.og_image) {
      attributes.value = {
        ...attributes.value,
        ogImage: props.og_image,
      };
    }

    if (!props.og_image) {
      // defineOgImageComponent(
      //   "OgImageDynamicPage",
      //   {
      //     name: props.name,
      //     title: props.page_title,
      //     url: props.url,
      //     description: props.og_description,
      //     theme: "#2955FF",
      //     colorMode: "light",
      //   },
      //   {
      //     alt: props.og_image_alt,
      //   }
      // );
    }

    useSeoMeta({
      name: props.meta_name,
      description: props.meta_description,
      keywords: props.meta_keywords,
      title: props.page_title,
      ogTitle: props.og_title,
      ogDescription: props.og_description,
      ogImageAlt: props.og_image_alt,
      ...attributes.value,
    });
  };

  nuxtApp.provide("seoModule", seoModuleFunc);
});
